// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  serverEndpoint: "https://swy.dev.mortar.tovarnaidej.com/api/v1",

  firebase: {
    apiKey: "AIzaSyDeb54TC6qDaedSKFr8l3dLZ-QLDNO9w8k",
    authDomain: "petra-beswy.firebaseapp.com",
    projectId: "petra-beswy",
    storageBucket: "petra-beswy.appspot.com",
    messagingSenderId: "216677090073",
    appId: "1:216677090073:web:3fbd8da94ff7d70a16fb88",
    measurementId: "G-X30KV1PH8J",
  },
  googleIdentityToolkit:
    "https://identitytoolkit.googleapis.com/v1/accounts:signInWithPassword?",
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
