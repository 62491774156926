import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  public workoutState: any = {}
  public recipesState: any = {}
  public subscriptionWorkoutList: any = []

  constructor() { }

  returnMessage(code: string) {
    switch (code) {
      case "auth/user-disabled": {
        return "Uporabnik ni aktiven.";
      }
      case "auth/user-not-found": {
        return "Uporabnik ne obstaja.";
      }
      case "auth/email-already-in-use": {
        return "E-mail že v uporabi!";
      }
      case "auth/invalid-email":{
        return "Napačen e-mail!";
      }
      case "auth/wrong-password":{
          return "Napačno geslo!";
        }
      case "auth/user-not-found": {
        return "Uporabnik ni registriran!";
      }
      case "auth/too-many-requests":{
          return "Prekoračeno število napačnih prijav. Poizkusite kasneje!";
      }
      default: {
        return "Napaka v izvajanju!";
      }
    }
  }
}
