import { Injectable } from "@angular/core";
import { CanActivate, Router, UrlTree, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { Observable } from "rxjs";
import { AuthService } from "../shared/auth.service";
import { SharedService } from "../shared/shared.service";

@Injectable({
  providedIn: "root",
})
export class SubscriptionGuard implements CanActivate {
  constructor(private toast: ToastrService, private router: Router, private shared: SharedService) {}
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    let userJson = localStorage.getItem("userInfo");
    let checkPurchase = false;

    if(!userJson){
      this.router.navigateByUrl("/login");
      return false;
    }

    /*
    if(route.url[0].toString() == "workout-detail"){
      if(this.shared.subscriptionWorkoutList.length > 0){        
        let subWorkout = this.shared.subscriptionWorkoutList.filter((i:any) => (i.id.toString() == route.url[1].toString()));
        
        if(subWorkout.length == 0){
          return true;
        }
      } else {
        checkPurchase = true;
      }
    }
    */

    let user = JSON.parse(userJson);
    if (!!user && user.purchases?.length > 0) {

      for (let index = 0; index < user.purchases.length; index++) {
        const purchasesInformation = user.purchases[index];
        
        if(purchasesInformation.subscription_id){
          if (purchasesInformation.valid) {
            localStorage.setItem("isSubuscription", "true");
            return true;
          } else if (!purchasesInformation.valid && purchasesInformation.valid_until) {
    
            var date1 = new Date(purchasesInformation.valid_until);
            var date2 = new Date();
    
            if (date1 >= date2) {
              localStorage.setItem("isSubuscription", "true");
              return true;
            } else if (date2 > date1) {
              //this.toast.info("Subscription expired ");
              //this.router.navigateByUrl("/subscription");
              //return false;
            }
    
          } else if (!purchasesInformation.valid && purchasesInformation.valid_until == null) {
            //this.toast.info("Subscription expired");
            //this.router.navigateByUrl("/subscription");
            //return false;
          }
        } else if(checkPurchase && purchasesInformation.transaction_id){
          if (purchasesInformation.valid) {
            return true;
          }
        }
      }
    }

    //this.toast.info("Subscription expired ");
    localStorage.setItem("isSubuscription", "false");
    this.router.navigateByUrl("/subscription");
    return false;
  }
}
