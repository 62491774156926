import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { NotFoundComponent } from "./components/not-found/not-found.component";
import { AuthGuard } from "./guards/auth.guard";
import { SubscriptionGuard } from "./guards/subscription.guard";

const routes: Routes = [
  { path: "", redirectTo: "/login", pathMatch: "full" },
  {
    path: "dashboard",
    loadChildren: () =>
      import("./components/dashboard/dashboard.module").then(
        (m) => m.DashboardModule
      ),
    canActivate: [AuthGuard],
  },
  // {
  //   path: "carddetails",
  //   loadChildren: () =>
  //     import("./components/card-detail-popup/card-detail-popup-routing.module").then(
  //       (m) => m.CardDetailPopupRoutingModule
  //     ),
  //   canActivate: [AuthGuard],
  // },

  {
    path: "forgot-password",
    loadChildren: () =>
      import("./components/forgot-password/forgot-password.module").then(
        (m) => m.ForgotPasswordModule
      ),
  },
  {
    path: "verify-email",
    loadChildren: () =>
      import("./components/verify-email/verify-email.module").then(
        (m) => m.VerifyEmailModule
      ),
  },
  {
    path: "verify-stripe",
    loadChildren: () =>
      import("./components/verify-stripe/verify-stripe.module").then(
        (m) => m.VerifyStripeModule
      ),
      canActivate: [AuthGuard],
  },
  {
    path: "verify-stripe/:session",
    loadChildren: () =>
      import("./components/verify-stripe/verify-stripe.module").then(
        (m) => m.VerifyStripeModule
      ),
      canActivate: [AuthGuard],
  },
  {
    path: "login",
    loadChildren: () =>
      import("./components/login/login.module").then((m) => m.LoginModule),
  },
  {
    path: "register",
    loadChildren: () =>
      import("./components/register/register.module").then(
        (m) => m.RegisterModule
      ),
  },
  {
    path: "podcast",
    loadChildren: () =>
      import("./components/podcast/podcast.module").then(
        (m) => m.PodcastModule
      ),
    canActivate: [AuthGuard, SubscriptionGuard],
  },
  {
    path: "best-support",
    loadChildren: () =>
      import("./components/best-support/best-support.module").then(
        (m) => m.BestSupportModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "workout-detail/:id",
    loadChildren: () =>
      import("./components/workout-detail/workout-detail.module").then(
        (m) => m.WorkoutDetailModule
      ),
    canActivate: [AuthGuard, SubscriptionGuard],
  },
  {
    path: "workout-programme",
    loadChildren: () =>
      import("./components/workout-programme/workout-programme.module").then(
        (m) => m.WorkoutProgrammeModule
      ),
    canActivate: [AuthGuard, SubscriptionGuard],
  },
  {
    path: "workout/:id",
    loadChildren: () =>
      import("./components/workout/workout.module").then(
        (m) => m.WorkoutModule
      ),
    canActivate: [AuthGuard, SubscriptionGuard],
  },
  {
    path: "workout/:id/:week",
    loadChildren: () =>
      import("./components/workout/workout.module").then(
        (m) => m.WorkoutModule
      ),
    canActivate: [AuthGuard, SubscriptionGuard],
  },
  {
    path: "workout/:id/:week/:type",
    loadChildren: () =>
      import("./components/workout/workout.module").then(
        (m) => m.WorkoutModule
      ),
    canActivate: [AuthGuard, SubscriptionGuard],
  },
  {
    path: "subscription",
    loadChildren: () =>
      import("./components/subscription/subscription.module").then(
        (m) => m.SubscriptionModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "payment",
    loadChildren: () =>
      import("./components/payment/payment.module").then(
        (m) => m.PaymentModule
      ),
    // canActivate: [AuthGuard,SubscriptionGuard],
  },
  {
    path: "recipe",
    loadChildren: () =>
      import("./components/recipe/recipe.module").then((m) => m.RecipeModule),
    canActivate: [AuthGuard, SubscriptionGuard],
  },
  {
    path: "recipe-detail/:id",
    loadChildren: () =>
      import("./components/recipe-detail/recipe-detail.module").then(
        (m) => m.RecipeDetailModule
      ),
    canActivate: [AuthGuard, SubscriptionGuard],
  },
  {
    path: "carddetails",
    loadChildren: () =>
      import("./components/card-detail-popup/card-detail-popup.module").then(
        (m) => m.CardDetailPopupModule
      ),
    canActivate: [AuthGuard, SubscriptionGuard],
  },
  {
    path: "my-profile",
    loadChildren: () =>
      import("./components/my-profile/my-profile.module").then(
        (m) => m.MyProfileModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'izbris-racuna',
    loadChildren: () =>
      import('./components/delete-account/delete-account.module').then(
        (m) => m.DeleteAccountModule
      ),
    // canActivate: [AuthGuard],
  },
  {
    path: "my-profile/:tab",
    loadChildren: () =>
      import("./components/my-profile/my-profile.module").then(
        (m) => m.MyProfileModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "measurements",
    loadChildren: () =>
      import("./components/measurements/measurements.module").then(
        (m) => m.MeasurementsModule
      ),
    canActivate: [AuthGuard, SubscriptionGuard],
  },
  {
    path: "**",
    component: NotFoundComponent,
  },
];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
