import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from "@angular/common/http";
import { catchError, Observable, throwError } from "rxjs";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { AngularFireAuth } from "@angular/fire/compat/auth";

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  private bypassTheUrls = [
    "forgot-password",
    "verify-email",
    "login",
    "register",
  ];
  constructor(private router: Router,private toast:ToastrService, private fireauth: AngularFireAuth,) {}
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const jwt = localStorage.getItem("authToken");
    const url = this.router.url.replace("/", "");
    if (!!jwt && !this.bypassTheUrls.includes(url)) {
      req = req.clone({
        headers: req.headers.append("Authorization", "Bearer " + jwt),
      });
    }
    return next.handle(req).pipe(
      catchError((err) => {
        if (err.status === 401) {
          // remove Bearer token and redirect to login page
          //localStorage.clear();
          //this.router.navigate(["/login"]);
          //this.toast.warning("Prosim, prijavite se znova","Seja potekla");

          this.fireauth.currentUser.then(user => {
            if(user){
              user.getIdToken().then((tkn: any) => {
                localStorage.setItem("authToken", tkn);
                this.router.navigate(["dashboard"]);
              })
            } else {
              localStorage.clear();
              this.router.navigate(["/login"]);
              this.toast.warning("Prosim, prijavite se znova","Potekla seja");
            }
          });
        }
        return throwError(err);
      })
    );
  }
}
