import { Injectable } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { Router } from "@angular/router";
import { GoogleAuthProvider, OAuthProvider } from "@angular/fire/auth";
import { BehaviorSubject, Observable } from "rxjs";
import { IUser } from "../models/login.interface";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { ToastrService } from "ngx-toastr";
import { SharedService } from "./shared.service";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  //private currentUserSubject: BehaviorSubject<IUser>;
  //public user: Observable<IUser>;
  
  constructor(
    private fireauth: AngularFireAuth,
    private router: Router,
    public fireservice: AngularFirestore,
    private http: HttpClient,
    private toastr: ToastrService,
    private sharedService: SharedService
  ) {
    //this.currentUserSubject = new BehaviorSubject<IUser>(JSON.parse(localStorage.getItem("user") || "{}"));
    //this.user = this.currentUserSubject.asObservable();
  }

  public getVerified(a: any) {
    //localStorage.setItem("user", JSON.stringify(a));
    //this.currentUserSubject.next(a);
  }

  public getVerifiedSubscription(a: any) {
    localStorage.setItem("subscription", JSON.stringify(a));
    // this.currentSubscriberSubject.next(a);
  }

  //login

  login(email: string, password: string) {
    // console.log('this.fireauth.signInWithEmailAndPassword(email, password)',this.fireauth.signInWithEmailAndPassword(email, password));

    return this.fireauth.signInWithEmailAndPassword(email, password);
  }

  //register

  register(email: string, password: string) {
    return this.fireauth.createUserWithEmailAndPassword(email, password);
  }

  // logout

  logout() {
    this.fireauth.signOut().then(
      () => {
        localStorage.removeItem("token");
        this.router.navigate(["/login"]);
      },
      (err) => {
        let returndMesaage = this.sharedService.returnMessage(err["code"]);
        this.toastr.error(returndMesaage || err.message);
      }
    );
  }

  //forgot Password

  forgotPasaword(email: string) {
    this.fireauth.sendPasswordResetEmail(email).then(
      () => {
        this.router.navigate(["/verify-email"]);
      },
      (err) => {
        let returndMesaage = this.sharedService.returnMessage(err["code"]);
        this.toastr.error(returndMesaage);
      }
    );
  }

  // emailvarification

  sendEmailForVarification(user: any) {
    user.sendEmailVerification().then(
      (res: any) => {
        this.router.navigate(["/verify-email"]);
      },
      (err: any) => {
        let returndMesaage = this.sharedService.returnMessage(err["code"]);
        this.toastr.error(returndMesaage || "Težave pri pošiljanju e-maila!");
      }
    );
  }

  // sign in with google

  googleSignIn() {
    return this.fireauth.signInWithPopup(new GoogleAuthProvider());
  }

  appleSignIn() {
    return this.fireauth.signInWithPopup(new OAuthProvider("apple.com"));
  }

  // appleSignIn(){
  //   return this.fireauth.signInWithPopup(new AppleAuthProvider).then((res:any)=>{
  //     this.router.navigate(['/dashboard']);
  //     localStorage.setItem('token', JSON.stringify(res.user?.uid));
  //   },err =>{
  //     alert(err.message);
  //   })

  // }

  // data add in firestore database
  addUser(item: any) {
    return this.fireservice.collection("user").add(item);
  }

  /*
  public get userValue(): IUser {
    //return this.currentUserSubject.value;
  }
  */

  
  // delete firebase acc
  deleteFirebaseUser(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.fireauth.currentUser.then((user) => {
        user?.delete().then(
          () => {
            resolve();
          },
          (err) => {
            let returndMesaage = this.sharedService.returnMessage(err["code"]);
            this.toastr.error(returndMesaage || err.message);
            reject(err);
          }
        );
      });
    });
  }

  // delete our backend user
  deleteBackendUser(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.http.delete(environment.serverEndpoint + "/auth/users/me/").subscribe(
        (res) => {
          localStorage.removeItem("authToken");
          this.router.navigate(["/login"]);
          resolve();
        },
        (err) => {
          let returndMesaage = this.sharedService.returnMessage(err["code"]);
          this.toastr.error(returndMesaage || err.message);
          reject(err);
        }
      );
    });
  }
  // delete both firebase and backend user
  deleteAccount() {
    this.deleteFirebaseUser().then(() => {
      this.deleteBackendUser().then(() => {
        console.log('Account deleted successfully');
      }).catch((err) => {
        console.log('Error deleting backend user', err);
      });
    }).catch((err) => {
      console.log('Error deleting firebase user', err);
    });
  }

  generaeAuthToken(data: any) {
    return this.http.post(
      environment.googleIdentityToolkit + "key=" + environment.firebase.apiKey,
      data
    );
  }
  getAuthToken(): string {
    return localStorage.getItem("authToken")!;
  }

 
  getStripeKeyFromDatabase() {
    return this.http.get(environment.serverEndpoint + "/paywall/stripe/config");
  }

  registerInBackned(userInfo: any, token: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      }),
    };
    return this.http.patch(
      environment.serverEndpoint + "/auth/users/me/",
      userInfo,
      httpOptions
    );
  }

  uploadProfilePhoto(file: any) {

    let token = this.getAuthToken();

    const httpOptions = {
      headers: new HttpHeaders({
        //"Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      }),
    };

    const formData = new FormData();
    formData.append("image", file);

    return this.http.patch(
      environment.serverEndpoint + "/auth/users/me/",
      formData,
      httpOptions
    );
  }

  loginInBackned(token: any) {
        const httpOptions = {
            headers: new HttpHeaders({
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            }),
        };
        return this.http.get(
            environment.serverEndpoint + "/auth/users/me/", httpOptions
        );
    }
}
