import { Injectable } from "@angular/core";
import { CanActivate, UrlTree, Router } from "@angular/router";
import { Observable } from "rxjs";
import { AuthService } from "../shared/auth.service";

@Injectable({
  providedIn: "root",
})
export class AuthGuard implements CanActivate {
  user2:any;
  constructor(private router: Router, private authService: AuthService) {}

  canActivate():
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {

    if (localStorage.getItem("authToken") && localStorage.getItem("userInfo")) {
      // logged in so return true
      return true;
    }
    this.router.navigateByUrl("/login");
    return false;
  }
}
